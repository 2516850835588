//
// Search
// ------------------------------------------------------------

.m-highlight {
    background-color: $yellow40;
    padding: 0 2px;
}

// Search page search box
// ------------------------------------------------------------

.m-search {
    padding-bottom: $space*2;
    form {
        position: relative;
        button {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            width: 39px;
			height: 39px;
            svg {
                width: 20px;
                height: 20px;
                margin-left: 0;
                fill: $green;
            }
            span {
				top: -9999em;
				position: absolute;
				display: inline-block;
			}
        }
    }
}

// Header Search
// ------------------------------------------------------------

.header-search {
	@include pie-clearfix;
	display: none;
	@include respond-to($vpMenu) {
		font-size: rem($typeMe);
		display: inline-block;
		width: 140px;
		input {
			float: left;
			display: inline-block;
			width: 100%;
			border: 0;
			border-bottom: 1px solid $navy60;
			outline: none;
			background-color: transparent;
			padding: 0.3em 0;
			line-height: 1;
			color: $white;
			&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				color: $white;
				opacity: 1; /* Firefox */
				}
			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				color: $white;
			}
			&::-ms-input-placeholder { /* Microsoft Edge */
				color: $white;
			}
		}
		button {
			position: absolute;
			cursor: pointer;
			top: 0;
			bottom: 0;
			right: 0;
			width: 32px;
			height: 28px;
			text-indent: -9999px;
			border: 0;
			padding-right: 0;
			color: #fff;
			svg {
				stroke: $green;
				right: 5px;
				width: 24px;
				height: 24px;
			}
		}
	}
	@include respond-to($vpE) {
		right: 0;
	}
}

.header-search__label {
	@include a11yhide;
	color: #fff;
}
