
// Brand colours
// ------------------------------------------------------------------

// Filter CSS Generator - https://codepen.io/sosuke/pen/Pjoqqp

// NIHR Palette

$navy: #193E72;
$navy80: #475989;
$navy60: #747CA3;
$navy40: #A2A4C1;
$navy20: #D0D0E0;

$coral: #EA5D4E;
$coral80: #EC7C72;
$coral60: #F29E95;
$coral40: #F4BDB8;
$coral20: #F8DDDB;

$orange: #F29330;
$orange80: #F6AB5D;
$orange60: #F9C187;
$orange40: #FCD6B0;
$orange20: #FDEBD8;

$yellow: #FED47A;
$yellow80: #FFDC97;
$yellow60: #FFE5B2;
$yellow40: #FFEECD;
$yellow20: #FFF6E6;

$purple: #6667AD;
$purple80: #8482BE;
$purple60: #A2A0D0;
$purple40: #C1BFE1;
$purple20: #E0DFF1;

$aqua: #2EA9B0;
$aqua80: #6FBAC0;
$aqua60: #9BCBD0;
$aqua40: #C1DDE0;
$aqua20: #E1EEF0;

$green: #46A86C;
$green80: #79B989;
$green60: #A1CAA6;
$green40: #C4DDC5;
$green20: #E2EEE3;

$grey: #ACBCC3;
$grey80: #BECAD0;
$grey60: #CED6DB;
$grey40: #DFE3E7;
$grey20: #EFF1F3;

$white: #fff;


// Non-brand feedbacks
// ------------------------------------------------------------------

$cError: $coral;
$cSuccess: $green;
$cAlert: $coral;


// Map for easy assignment loops + swatches
// These must match the colors above
// ------------------------------------------------------------------

$colors: (
	navy: $navy,
	navy80: $navy80,
	navy60: $navy60,
	navy40: $navy40,
	navy20: $navy20,
	coral: $coral,
	coral80: $coral80,
	coral60: $coral60,
	coral40: $coral40,
	coral20: $coral20,
	orange: $orange,
	orange80: $orange80,
	orange60: $orange60,
	orange40: $orange40,
	orange20: $orange20,
	yellow: $yellow,
	yellow80: $yellow80,
	yellow60: $yellow60,
	yellow40: $yellow40,
	yellow20: $yellow20,
	purple: $purple,
	purple80: $purple80,
	purple60: $purple60,
	purple40: $purple40,
	purple20: $purple20,
	aqua: $aqua,
	aqua80: $aqua80,
	aqua60: $aqua60,
	aqua40: $aqua40,
	aqua20: $aqua20,
	green: $green,
	green80: $green80,
	green60: $green60,
	green40: $green40,
	green20: $green20,
	grey: $grey,
	grey80: $grey80,
	grey60: $grey60,
	grey40: $grey40,
	grey20: $grey20
);

// Assign colours to declarative variables
// ------------------------------------------------------------------

// Actions
$cAction: $navy;
$cActionRespond: $green;

// Buttons
$cButtonPrimary: $green80;

// Default text colors
$cHeading: $navy;
$cText: $navy;

// Form colors
$cLabel: $navy;
$cFormNotes: $navy;
$cFocus: $green40;

// Misc. color assignments
$cBorder: $grey;
