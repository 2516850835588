@use 'sass:math';

// Button defaults
// ------------------------------------------------------------------

// Button base style
%button {
	background-image: none;
	border-radius: $btnRadius;
	border: 2px solid $navy;
	color: $navy;
	cursor: pointer;
	display: inline-block;
	font-weight: $fontNormal;
	padding: 0 $btnPadding;
	position: relative;
	text-align: center;
	text-align: left;
	text-decoration: none;
	transition: all, ease-in-out, 0.3s;
	vertical-align: middle;
	span {
		font-size: inherit;
		font-weight: inherit;
	}
	&:focus {
		outline: none;
	}
}

// Sizes
// ------------------------------------------------------------------

.button,
a.button,
button.button {
	@extend %button;
	font-size: rem($typeS);
	font-weight: $fontBold;
	line-height: $btnDefault;
	background-color: $cButtonPrimary;
	border: 2px solid $green;
	transition-duration: 0.1s;
	// color: $white;
	border-radius: $space;
	svg {
		color: $navy;
	}
	@include link-active-styles {
		text-decoration: none;
		// transition-duration: 0.1s;
	}
	&--small {
		font-size: rem($typeXS);
		line-height: $btnSmall;
		padding: 0 $btnPaddingSmall;
	}
	&--icon {
		padding-right: $btnPadding*3;
		text-align: left;
		svg {
			position: absolute;
			top: 50%;
			right: $btnPadding*.8;
			transform: translateY(-50%);
			transition-duration: 0.3s;
			transition-property: transform;
			transition-timing-function: ease-out;
			transform-origin: top;
			height: 18px;
			width: 20px;
			display: block;
			color: $navy;
		}
		@include link-active-styles {
			svg {
				animation-name: hvr-icon-pop;
				animation-duration: 0.3s;
				animation-timing-function: linear;
				animation-iteration-count: 1;
			}
		}
	}
	// Types
	&--primary {
		background-color: $cButtonPrimary;
		border-color: $cButtonPrimary;
		@include link-active-styles {
			background: $green;
			border-color: $green;
		}
	}
	&--secondary {
		background-color: $white;
		border-color: $green;
		color: $navy;
		svg {
			color: $navy;
		}
		@include link-active-styles {
			background-color: $green20;
			border-color: $green80;
		}
	}
	&--tertiary {
		background-color: $white;
		border-color: $white;
		color: $navy;
		svg {
			color: $navy;
		}
		@include link-active-styles {
			background-color: $green20;
			border-color: $green;
		}
	}
	&--quarternary {
		background-color: transparent;
		border-color: $white;
		color: $navy;
		svg {
			color: $navy;
		}
		@include link-active-styles {
			border-color: $purple;
		}
	}
	&--quinary {
		background-color: $green80;
		border-color: $green80;
		color: $navy;
		svg {
			color: $navy;
		}
		@include link-active-styles {
			border-color: $green;
			background-color: $green40;
		}
	}
	&--senary {
		background-color: transparent;
		border-color: $navy80;
		color: $white;
		svg {
			color: $white;
		}
		@include link-active-styles {
			border-color: $navy60;
			background-color: $navy20;
			color: $navy;
			svg {
				color: $navy;
			}
		}
	}
	&--a11y {
		position: relative;
		overflow: hidden;
		span {
			top: -9999em;
			position: absolute;
			display: inline-block;
		}
		&:after {
			content: attr(title);
			display: inline-block;
			color: inherit;
		}
	}
	&--disabled {
		cursor: not-allowed;
		background-color: $grey;
		border-color: $grey;
		color: #fff;
		&:focus {
			outline: none;
			text-decoration: none;
		}
		@include link-active-styles {
			text-decoration: none;
		}
		&.button--icon {
			svg {
				color: $white;
			}
		}
	}
	&--block {
		display: block;
	}
	&--right {
		@include respond-to($vpC) {
			float: right;
		}
	}
}