@use 'sass:math';

// Root base
// ------------------------------------------------------------------

%typebaseRoot {
	line-height: $lineheightBase;
	margin-bottom: 0;
	margin-top: 0;
}

// Kerning
// ------------------------------------------------------------------

%kernTight 				{ letter-spacing: -0.125rem; font-kerning: normal; }
%kernLoose 				{ letter-spacing: 0.3rem; }
%kernUltraLoose 		{ letter-spacing: 0.76rem; }

// Typescale
// ------------------------------------------------------------------

.typeXXXL {
	@extend %typebaseRoot;
	@include sharp-text;
	font-size: rem($typeXXXL);
}

.typeXXL {
	@extend %typebaseRoot;
	// @include sharp-text;
	font-size: rem($typeXXL);
}

.typeXL {
	@extend %typebaseRoot;
	font-size: rem($typeXL);
}

.typeL {
	@extend %typebaseRoot;
	font-size: rem($typeL);
}

.typeM {
	@extend %typebaseRoot;
	font-size: rem($typeM);
}

// Median
.typeMe {
	@extend %typebaseRoot;
	font-size: rem($typeMe);
}

.typeS {
	@extend %typebaseRoot;
	font-size: rem($typeS);
}

.typeXS {
	@extend %typebaseRoot;
	font-size: rem($typeXS);
}


// Heading styles
// ------------------------------------------------------------------

.heading {
	display: block;
	font-family: $fontHeading;
	color: $cHeading;
	@include sharp-text;
}

// Default text
// ------------------------------------------------------------------

.text {
	@extend %typebaseRoot;
	font-size: rem($typeDefault);
}

.break-words {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;
	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

// Default lists
// ------------------------------------------------------------------

%textList {
	list-style-position: outside;
	padding-left: $space;
	li {
		@extend .text;
	}
}

%listlead {
	padding-bottom: rem(math.div($typeDefault, 2));
	&:last-of-type {
		padding-bottom: 0;
	}
}

.unordered_list {
	list-style-type: disc;
	@extend %textList;
	ul {
		list-style-type: disc;
		padding-left: rem(20);
	}
}

.ordered_list {
	list-style-type: decimal;
	@extend %textList;
	ol {
		list-style-type: decimal;
		padding-left: rem(20);
	}
}

.simple_list {
	list-style-type: none;
	@extend %textList;
	ol,ul {
		list-style-type: none;
		padding-left: rem(20);
	}
}

.description_list {
	overflow: hidden;
	margin-left: 0;
	margin-right: 0;
	dt,dd {
		@extend .text;
		@include leading_adjust(2);
		margin-left: 0;
		margin-right: 0;
	}
	dt {
		display: block;
	}
	dd {
		display: block;
		margin-left: rem(18);
		+ dt {
			margin-top: rem(18);
		}
	}
	code {
		display: inline;
	}
}

// Typebase (for runs of text)
// ------------------------------------------------------------------

.typebase {
	p {
		@extend .text;
		margin-top: rem($typeDefault);
		display: block;
	}
	ul,
	ol {
		@extend %textList;
		margin-top: rem($typeDefault);
	}
	dl {
		margin: rem($typeDefault*1.5) 0;
		@extend .description_list;
	}
	ul {
		@extend .unordered_list;
	}
	ol {
		@extend .ordered_list;
	}
	li {
		@extend %listlead;
	}
	img {
		display: block;
		max-width: 100%;
		height: auto;
		margin-top: rem($typeDefault*4);
	}
	h1, h2, h3, h4, h5, h6 {
		font-weight: $fontBold;
		@extend .heading;
		position: relative;
		margin-top: rem($typeDefault*2);
		@include leading_adjust(4);
	}
	h1 {
		@extend .typeXXXL;
	}
	h2 {
		@extend .typeXXL;
	}
	h3 {
		@extend .typeXL;
	}
	h4 {
		@extend .typeL;
	}
	h5 {
		@extend .typeM;
	}
	h6 {
		@extend .typeMe;
	}

	// Remove next para or list top margin
	// h1 + p,
	h2 + p,
	h3 + p,
	h4 + p,
	h5 + p,
	h6 + p,
	h1 + ol,
	h2 + ol,
	h3 + ol,
	h4 + ol,
	h5 + ol,
	h6 + ol,
	h1 + ul,
	h2 + ul,
	h3 + ul,
	h4 + ul,
	h5 + ul,
	h6 + ul {
		margin-top: rem($typeDefault);
	}

	// Tear out the initial top margin to allow things to flush together
	> h1:first-child,
	> h2:first-child,
	> h3:first-child,
	> h4:first-child,
	> h5:first-child,
	> h6:first-child,
	> img:first-child,
	// > p:first-child,
	> ol:first-child,
	> ul:first-child,
	> media-video-wrapper:first-child  {
		margin-top: 0;
	}

	// Stop empty spacing
	br + br,
	h1:empty,
	h2:empty,
	h3:empty,
	h4:empty,
	h5:empty,
	h6:empty,
	p:empty,
	ul:empty,
	ol:empty,
	span:empty,
	cite:empty,
	div:empty {
		display: none;
	}
}

.typebase--additionals {
	> p:first-of-type {
		font-size: rem($typeM);
	}
	.hero & {
		p {
			font-size: rem($typeM);
		}
		br + br {
			display: inline-block;
		}
	}
}

.typebase--centred {
	text-align: center;
}