// List
// ------------------------------------------------------------

.m-list {
    list-style: none;
    font-size: rem($typeDefault);
    margin:  $space 0;
    &--columns {
        columns: 1;
        @include respond-to($vpB) {
            columns: 2;
        }
        @include respond-to($vpC) {
            columns: 3;
        }
    }
}

.link-list {
	list-style: none;
	li {
		@include respond-to($vpC) {
			display: inline;
			margin-right: $space;
			padding-right: $space;
			border-right: 2px solid $aqua60;
			&:last-child {
				border-right: 0;
				margin-right: 0;
				padding-right: 0;
			}
		}
	}
	a {
		text-decoration: none;
		border-bottom-color: transparent;
		font-weight: $fontBold;
		@include link-active-styles {
			border-bottom-color: $navy;
		}
	}
	svg{
		width: 20px;
		height: 20px;
		top: -1px;
		position: relative;
		margin-left: 5px;
	}
}