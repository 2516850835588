// // ------------------------------------------------------------
// // Contact form 7

.wpcf7 form .wpcf7-response-output {
    padding: $space*.5 $space;
	position: relative;
	background-color: #fff;
	border: 4px solid $navy60;
	color: $cHeading;
	border-color: $navy60;
	font-size: rem($typeS);
	p, ul {
		margin-bottom: 10px;
	}
	ul {
		list-style-position: inside;
		padding-left: $space;
	}
}

.wpcf7 form.init .wpcf7-response-output {
    display: none;
}

.wpcf7 form.sent .wpcf7-response-output {
    border-color: $green;
}

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
    border-color: $cError;
}

.wpcf7 form.spam .wpcf7-response-output {
    border-color: $cError;
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
    border-color: $cError;
}

.wpcf7-not-valid,
.form-item.wpcf7-not-valid {
	border-color: $cError;
}

.wpcf7-not-valid-tip {
    color: $cError;
    font-size: rem($typeS);
    font-weight: normal;
    display: block;
}

.screen-reader-response {
  display: none;
}