// Cookies
// ------------------------------------------------------------

.cookie-bar{
	position: fixed;
	width: 100%;
	bottom: 0;
	padding: 10px 5px;
	bottom: -250px;
	color: white;
	background: #000;
	font-family: arial;
	font-size: rem($typeXS);
	text-align: center;
	transition: all .4s;
	z-index: 100;
	&--active{
		bottom: 0;
		transition: none;
	}
	p {
		@include respond-to($vpC) {
			display: inline;
		}
	}
	.button {
		clear: both;
		@include respond-to-max($vpC) {
			margin: 10px 0;
		}
		@include respond-to($vpC) {
			display: inline;
            margin-left: 10px;
        }
	}
	a:not(button){
		margin-left: 10px;
		color: $white;
		border-bottom: 1px solid $white;
	}
}