@use 'sass:math';

.hero {
	position: relative;
	padding: $space*4 $space;
	background-color: $navy80;
	@include respond-to($vpC) {
		padding: $space*4 0;
	}
	&--news,
	&--contact {
		background-color: $navy;
	}
	&--article ,
	&--getting-here{
		background-color: $green40;
	}
	&--facility,
	&--industry {
		background-color: $green20;
	}
	&--ppi,
	&--training {
		background-color: $yellow40;
	}
	&--home {
		padding: 0;
		background: $white;
		color: $navy;
	}

	&--events {
		background-color: $green;
		padding-top: $space*6;
	}
	&--clip {
		padding-bottom: $space*6;
	}
	&--split,
	&--split--green {
		position: relative;
		z-index: 1;
		@include respond-to($vpC) {
			&:after {
				content: "";
				width: 40%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: $navy80;
			}
		}
	}
	&--split--green {
		&:after {
			background-color: $green40;
		}
	}
	&--image {
		padding: 0;
	}
}

.hero__content {
	z-index: 6;
	position: relative;
	padding: $space;
	color: $white;
	@include respond-to($vpBa){
		max-width: 800px;
		width: 55%;
		float: left;
		margin-bottom: 0;
	}
	@include respond-to($vpC){
		padding: 0 0 0 $space;
	}
	.hero__description,
	.hero__meta {
		+ .button {
			margin-top: $space;
		}
	}
	.button {
		position: relative;
		left: 0;
		top: 0;
	}
	.hero--home &,
	.hero--image & {
		@include respond-to($vpBa){
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.hero--facility &,
	.hero--industry &,
	.hero--ppi &,
	.hero--training &,
	.hero--article & {
		color: $navy;
	}
	.hero--news & {
		margin: 0 auto $space;
		width: auto;
		max-width: 800px;
		float: none;
		top: auto;
		text-align: center;
		z-index: 6;
		position: relative;
		transform: none;
		a {
			color: $white;
			@include link-active-styles {
				border-bottom: 1px solid $white;
			}
		}
		svg {
			stroke: $green;
		}
	}
}

.hero__aside {
	position: relative;
	z-index: 5;
	@include respond-to($vpBa) {
		width: 40%;
		float: right;
		position: relative;
	}
}

.hero__aside__content {
	padding: $space*2;
}

.hero__link {
	font-size: rem($typeMe);
	border-bottom: 0;
	svg {
		width: 20px;
		margin-left: $space*.5;
	}
	@include link-active-styles {
		border-bottom: 2px solid $navy;
	}
}

.hero__media {
	overflow: hidden;
	position: relative;
	background-size: cover;
	background-position: center;
	@include ratio(4 3);
	object,embed,iframe  {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.hero__collage {
	display: grid;
  	grid-template-columns: repeat(2, 1fr);
}

.image__motif {
	overflow: hidden;
	position: relative;
	background-size: 50%;
	background-position: center;
	@include ratio(1 1);
	background-color: $navy;
	@include respond-to($vpC) {
		@include ratio(4 3);
	}
	&--left {
		background-position: bottom left;
		background-size: 65%;
	}
	&--yellow {
		background-color: $yellow80;
	}
	&--green {
		background-color: $green80;
	}
	&--green-mid {
		background-color: $green80;
	}
	&--green-light {
		background-color: $green40;
	}
}

.hero__media__caption,
.hero__aside__caption {
	background-color: #fff;
	font-size: rem($typeXS);
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 5px 15px;
}

.hero__media__caption {
	position: absolute;
	bottom: 0;
	left: 0;
	float: none;
	z-index: 1000;
}

.hero__heading {
	@extend .heading;
	font-size: rem($typeL);
	line-height: 1.2;
	margin-bottom: $space;
	color: #fff;
	@include respond-to($vpC) {
		font-size: rem($typeXL);
	}
	@include respond-to($vpD) {
		font-size: rem($typeXXL);
	}
	@include respond-to($vpE) {
		font-size: rem($typeXXXL);
	}
	.hero--facility &,
	.hero--industry &,
	.hero--ppi &,
	.hero--training &,
	.hero--article &,
	.hero--home &,
	.hero--getting-here &{
		color: $navy;
	}
	.hero--takeover & {
		a {
			color: $white;
			@include link-active-styles {
				border-bottom: 2px solid $white;
			}
		}
	}
	a {
		border: 0;
		@include link-active-styles {
			border-bottom: 2px solid $navy;
		}
	}
}

.hero__subheading {
	@extend .heading;
	font-size: rem($typeL);
	font-weight: $fontBold;
	line-height: 1.2;
	margin-bottom: $space;
	color: #fff;
	.hero--getting-here & {
		color: $navy;
	}

}

.hero__description {
	font-size: rem($typeMe);
	@include respond-to($vpC) {
		font-size: rem($typeM);
	}
	@include respond-to($vpD) {
		font-size: rem($typeL);
	}
}

.hero__meta {
	list-style: none;
	font-size: rem($typeDefault);
	margin: math.div($space,1.5) 0 math.div($space,2) 0;
	color: $white;
	li {
		margin-right: math.div($space,2);
		svg {
			width: 20px;
			height: 20px;
			margin-right: 10px;
			display: inline-block;
			stroke: $purple;
			stroke-width: 1.5px
		}
		a {
			text-decoration: none;
			border-bottom: transparent;
			color: $white;
			border-bottom: 1px solid $white;
			@include link-active-styles {
				border-bottom: 2px solid $white;
			}
		}
	}
	&.hero__meta--icons {
		li {
			padding-left: 25px;
			position: relative;
			svg {
				position: absolute;
				left: 0;
				top: 4px;
			}
		}
	}
	.hero--getting-here &,
	.hero--article & {
		color: $navy;
		li a {
			color: $navy;
			border-bottom: 1px solid $navy;
			@include link-active-styles {
				border-bottom: 2px solid $navy;
			}
		}
	}
}

.hero__form {
	.js & .button {
		display: none;
	}
}

.hero__search {
	position: relative;
	padding-right: $space;
	label {
		@include a11yhide;
		color: $white;
	}
	input {
		border-color: #fff;
		font-size: rem($typeM);
	}
	button {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		line-height: 1;
		height: 40px;
		width: 40px;
		background-color: $cButtonPrimary;
		border-color: $cButtonPrimary;
		span {
			top: -9999em;
			position: absolute;
			display: inline-block;
		}
		svg {
			margin: 18px 0 0 8px;
			transform: translateY(-50%);
			transition-duration: 0.3s;
			transition-property: transform;
			transition-timing-function: ease-out;
			transform-origin: top;
			height: 20px;
			width: 20px;
			display: block;
			stroke: $white;
			stroke-width: 1.5px;
		}
		@include link-active-styles {
			svg {
				animation-name: hvr-icon-pop;
				animation-duration: 0.3s;
				animation-timing-function: linear;
				animation-iteration-count: 1;
			}
		}
	}
}