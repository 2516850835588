.section {
    position: relative;
    padding: $space*2 $space;
    clear: both;
    @include respond-to($vpC) {
        padding: $space*3 0;
    }
    > div {
        position: relative;
        z-index: 2;
    }
    &--minor {
        padding: $space 0;
        @include respond-to($vpC) {
        	padding: $space*2 0;
        }
    }
    &--highlight {
        background-color: $green20;
    }
    &--highlight-2 {
        background-color: $green40;
        .grid-sidebar__main{
        	position: relative;
        	&:after {
        		content: "";
	        	background-color: $green20;
	        	width: 1px;
	        	position: absolute;
	        	top: 0;
	        	right: -9.5%;
	        	bottom: 0;
	        }
		}
		.grid-sidebar--reverse {
			.grid-sidebar__main:after {
				display: none;
			}
		}
    }
    &--paddless {
        padding: 0;
    }
    &--divide {
        border-top: 1px solid $green20;
    }
    &--leader {
    	margin-top: $space;
        @include respond-to($vpC) {
        	margin-top: $space*3;
        }
    }
    &--split,
	&--split-reverse {
		background-color: $aqua40;
		position: relative;
		&:after {
			content: "";
			width: 50%;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background-color: $aqua20;
		}
		z-index: 1;
	}
	&--split-reverse {
		background-color: $aqua20;
		&:after {
			background-color: $aqua40;
		}
	}
}

.section__header {
    margin-bottom: $space*2;
}

.section__heading {
    @extend .heading;
    line-height: 1.3;
    font-size: rem($typeXXL);
    font-weight: $fontBold;
    margin-bottom: $space*2;
    .section--grey &,
    .section--purple &,
    .section--lowlight & {
        color: #fff;
    }
    .section--minor & {
    	margin-bottom: $space;
    }
    &--centred {
        text-align: center;
    }
}

.section__heading__link {
    display: block;
    clear: left;
    @include respond-to($vpB) {
        float:right;
    }
	font-size: rem($typeMe);
	margin-top: 10px;
	border-width: 0;
	svg {
		margin-top: 2px;
		margin-left: 5px;
		height: 20px;
		width: 20px;
		display: block;
		float: right;
		filter: brightness(0) saturate(100%) invert(12%) sepia(88%) saturate(4721%) hue-rotate(273deg) brightness(104%) contrast(88%);
	}
	@include link-active-styles {
		border-width: 2px;
	}
}

.section__subheading {
    @extend .heading;
    font-size: rem($typeXL);
    font-weight: $fontBold;
    margin-bottom: $space*2;
    svg {
    	height: 40px;
    	width: 40px;
    	margin-right: $space;
    	color: $green;
    }
    p {
    	font-weight: $fontNormal;
		font-size: rem($typeXL);
		display: block;
    }
}

.section__copy  {
    font-size: rem($typeM);
}

.section__centred {
	padding: $space;
	text-align: center;
}