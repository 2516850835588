@use 'sass:math';

.aside {
	padding-top: $space*2;
	margin-bottom: $space*2;
	border-top: 1px solid $grey20;
	&--dark {
		color: #fff;
	}
	&:first-child,
	&--close {
		padding-top: 0;
		border-top: 1px solid transparent;
	}
}

.aside__heading {
	@extend .heading;
	font-size: rem($typeL);
	font-weight: $fontBold;
	margin-bottom: $space;
	position: relative;
	.aside--dark & {
		background: $navy;
		color: #fff;
		margin-bottom: 0;
		padding: $space*.5 $space;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
	}
}

.aside__text {
	font-size: rem($typeDefault);
	margin: $space*.75 0 $space*1.5;
}

.aside__content {
	background: $grey40;
	font-size: rem($typeDefault);
	margin-bottom: math.div($space,2);
	padding: 0 $space $space;
	p {
		margin-top: rem($typeDefault);
		display: block;
		&:first-child {
			margin-top: 0;
		}
	}
	a:not(.button) {
		font-size: rem($typeL);
		@include link-active-styles {
			color: $yellow40;
		}
	}
	.aside--dark & {
		background: $navy;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}
}

.aside__list {
	list-style: none;
	font-size: rem($typeDefault);
	li {
		display: block;
		font-size: rem($typeMe);
		margin-bottom: $space;
		a {
			font-size: rem($typeL);
			display: block;
			border-bottom: 0;
			position: relative;
			svg{
				width: 20px;
				height: 20px;
				display: inline-block;
				position: absolute;
				top: 0;
				left: 9999px;
			}
			&:hover {
				svg{
					position: relative;
					left: auto;
					margin-top: -2px;
					color: $green;
				}
			}
		}
	}
}