@use 'sass:math';

.quote,
blockquote {
	border-top: 1px solid $navy20;
	border-bottom: 1px solid $navy20;
	padding: $space*2 0 $space*2 $space*5;
	position: relative;
	margin: $space*2 0;
	.typebase & {
		margin-top: 3.2rem;
	}
	&:before {
		content: "\201C";
		font-size: 180px;
		color: $yellow80;
		position: absolute;
		left: -6px;
		top: -26px;
		font-family: 'Helvetica';
	}
}

.typebase blockquote p,
.quote__content p {
	font-size: rem($typeL);
	font-style: italic;
	margin-bottom: math.div($space,2);
}

.typebase blockquote cite,
.quote__citation {
	margin-bottom: math.div($space,2);
	font-size: rem($typeDefault);
	font-style: italic;
}