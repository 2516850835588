
// Font declarations
// ------------------------------------------------------------------

// @import url('https://fonts.googleapis.com/css?family=Lato:400,700');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');

$fontBrand:		"Lato", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
$fontMono:      monospace!default;

// Font weights (can be numerical or actual font files)
// ------------------------------------------------------------------

$fontThin:        100!default;
$fontUltraLight:  200!default;
$fontLight:       300!default;
$fontNormal:      400!default;
$fontMedium:      500!default;
$fontSemiBold:    600!default;
$fontBold:        700!default;
$fontExtraBold:   800!default;
$fontBlack:       900!default;


// Font defaults
// ------------------------------------------------------------------

$fontDefault:   $fontBrand!default;
$fontHeading:   $fontBrand!default;