
.row {
	@include pie-clearfix;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	min-width: auto;
	max-width: $gridMax;
	overflow: visible;
	&--static {
		position: static;
	}
	padding-left: $space;
	padding-right: $space;
	@include respond-to-min-max($vpC,$vpD) {
		.header & {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.hero & {
		padding-left: 0;
		padding-right: 0;
	}
	@include respond-to($vpF) {
	    padding-left: 0;
	    padding-right: 0;
	}
	&--no-space {
		padding-left: 0;
	}
	.navigation-primary & {
		padding-left: 0;
		padding-right: 0;
	}
}

.hero .row--padded {
	padding-left: $space;
	padding-right: $space;
}