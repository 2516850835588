@use 'sass:math';

.cta {
	background: $green20;
	position: relative;
	overflow: hidden;
	&--dark {
		background: $navy;
		color: $white;
	}
	&--image {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	&--centred {
		text-align: center;
	}
	.typebase & {
		margin-top: 3.2rem;
	}
	.grid-sidebar__aside  & {
		padding-top: $space*2;
		margin-bottom: $space*2;
		border-top: 1px solid $grey20;
	}
}

.cta__heading {
	@extend .heading;
	font-size: rem($typeM);
	margin-bottom: math.div($space,2);
}

.cta__content {
	padding: $space $space*1.5;
	font-size: rem($typeDefault);
	margin-bottom: math.div($space,2);
	.cta--image & {
		padding-left: 200px;
	}
}

.cta__image {
	position: absolute;
	left: 0;
	top: 0;
	width: 180px;
	img {
		width: 180px;
	}
}