@use 'sass:math';

// .downloads {
// 	padding-top: $space*2;
// }

// .downloads__heading {
// 	@extend .heading;
// 	font-size: rem($typeL);
// 	line-height: 1.3;
// 	transition: all, ease-in-out, 0.3s;
// 	margin-bottom: $space*.5;
// }

.downloads__excerpt {
	font-size: rem($typeDefault);
	margin-bottom: $space;
}

.downloads__meta {
	position: relative;
	font-size: rem($typeDefault);
	list-style: none;
	margin-bottom: $space;
	.download__meta__heading {
		font-weight: $fontSemiBold;
	}
	li {
		margin-bottom: 5px;
		svg {
			width: 24px;
			height: 24px;
			vertical-align: text-baseline;
			margin: 0 10px 0 0;
			display: inline-block;
			stroke: $green;
			stroke-width: 2px;
		}
		a {
			text-decoration: none;
			border-color: transparent;
			@include link-active-styles {
				border-color: $navy80;
			}
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
}