// ------------------------------------------------------------
// Alerts
// ------------------------------------------------------------

.alert {
	padding: $space*.5 $space;
	position: relative;
	background-color: #fff;
	border: 2px solid $green80;
	color: $cHeading;
	border-radius: $border-radius;
	p {
		font-size: rem($typeS);
		margin-top: 0;
	}
}

.alert--notice {
	border-color: $yellow80;
	background-color: $yellow20;
}

.alert--error {
	border-color: $coral80;
	background-color: $coral20;
}

.alert--success {
	border-color: $green80;
	background-color: $green20;
}