.filter {
    background-color: $grey20;
    padding: $space;
    border-radius: $border-radius;
    margin-bottom: $space*2;
    @include respond-to($vpC) {
        padding: 0;
        background-color: transparent;
    }
}

// .filter__heading {
//     @extend .heading;
//     position: relative;
//     font-size: rem($typeL);
//     outline: none;
//     display: block;
//     width: 100%;
//     cursor: pointer;
//     svg {
//         position: absolute;
//         top: 0;
//         right: 0;
//         width: 40px;
//         height: 40px;
//         transition: all, ease-in-out, 0.3s;
//     }
//     &--open {
//         svg {
//             transform: rotate(180deg);
//         }
//     }
//     @include respond-to($vpC) {
//     	display: none;
//         margin-bottom: $space;
//         cursor: default;
//         svg {
//             display: none;
//         }
//     }
// }

// .filter__reveal {
//     overflow: hidden;
//     @include respond-to-max($vpC) {
//         html.js & {
//             transition: all, ease-in-out, 0.25s;
//             max-height: 0;
//         }
//         &--open {
//             html.js & {
//                 margin-top: $space;
//                 max-height: 820px;
//             }
//         }
//     }
// }

.filter__form {
    @include gridCalcRepeat(1,20px,20px);
    grid-row-gap: 20px;
    @include respond-to($vpC) {
        @include gridCalcRepeat(9);
        @supports (display: grid) {
            grid-row-gap: 20px;
            grid-gap: 20px;
        }
        .form-field {
            width: 21%;
            margin-left: 1%;
            margin-bottom: 1em;
            float: left;
            &:last-child {
                width: 10%;
            }
            .button {
                margin-top: 30px;
                // &.button--utility {
                //     margin-top: 10px;
                // }
            }
            @supports (display: grid) {
                width: auto;
                margin-left: 0;
                margin-bottom: 0;
                float: none;
                &:last-child {
                    width: auto;
                }
            }
            @supports (display: -ms-grid) {
                width: 21%;
                float: left;
                &:last-child {
	                width: 10%;
	            }
            }
            @supports (display: grid) {
            	grid-column: span 2;
	            // &:first-child,
	            &:last-child {
	                grid-column: span 1;
	            }
	        }
        }
    }
    .filter--news & {
    	 @include respond-to($vpC) {
        	@include gridCalcRepeat(6);
        	.form-field {
	        	@supports (display: grid) {
	            	grid-column: span 2;
		            &:first-child,
		            &:last-child {
		                grid-column: span 1;
		            }
		        }
		    }
        }
    }
}