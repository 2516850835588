// Sidebar
.grid-sidebar {
	&__main {
		margin-bottom: $space*2;
	}
	@include respond-to($vpC) {
		@include gridCalc('true');
		grid-column-gap: 10px !important;
		&__main {
			margin-bottom: 0;
			@include gridPosition(1, 7);
			@include gridRow(1);
			width: 65%;
			float: left;
			@supports (display: grid) {
				width: auto;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 65%;
				float: left;
			}
			.grid-sidebar--reverse & {
				@include gridPosition(6, 8);
				float: right;
				@supports (display: -ms-grid) {
					float: right;
				}
			}
		}
		&__aside {
			@include gridPosition(9, 5);
			@include gridRow(1);
			width: 30%;
			float: right;
			margin-left: 5%;
			@supports (display: grid) {
				width: auto;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 30%;
				float: right;
				margin-left: 5%;
			}
			.grid-sidebar--reverse & {
				@include gridPosition(1, 5);
				float: left;
				margin-right: 5%;
				@supports (display: -ms-grid) {
					float: left;
					margin-right: 5%;
				}
			}
		}
	}
}

// 2 column grid
.grid-2up {
	@include gridCalcRepeat(1);
	@include respond-to($vpC) {
		@include gridCalcRepeat(2);
		grid-column-gap: 40px !important;
		&--huge {
			grid-column-gap: 120px !important;
		}
	}
	> div, > a {
		margin-bottom: $space;
		@include respond-to($vpC) {
			width: 49%;
			margin-left: 2%;
			float: left;
			&:nth-child(2n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 49%;
				float: left;
			}
		}
	}
}

// 3 column grid
.grid-3up {
	@include gridCalcRepeat(1);
	@include respond-to-min-max($vpB,$vpC) {
		@include gridCalcRepeat(2);
	}
	@include respond-to($vpC) {
		@include gridCalcRepeat(3,40px,40px);
	}
	&--trailer {
		margin-bottom: $space;
	}
	> div, > a {
		@include respond-to-max($vpC) {
			margin-bottom: $space;
		}
		@include respond-to($vpC) {
			width: 32%;
			margin-left: 2%;
			float: left;
			&:nth-child(3n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 33%;
				float: left;
			}
		}
		@include respond-to-min-max($vpB, $vpC) {
			width: 48%;
			margin-left: 2%;
			float: left;
			&:nth-child(2n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 50%;
				float: left;
			}
		}
	}
}

// 4 column grid
.grid-4up {
	@include gridCalcRepeat(1);
	@include respond-to($vpB) {
		@include gridCalcRepeat(2);
	}
	@include respond-to($vpC) {
		@include gridCalcRepeat(4);
	}
	> div, > a {
		margin-bottom: $space;
		@include respond-to-max($vpC) {
			width: 49%;
			margin-left: 2%;
			float: left;
			&:nth-child(2n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
				clear: none;
			}
			@supports (display: -ms-grid) {
				width: 49%;
				float: left;
			}
		}
		@include respond-to($vpC) {
			width: 23.5%;
			margin-left: 2%;
			float: left;
			&:nth-child(4n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 23.5%;
				float: left;
			}
		}
	}
}

.grid-divides {
	> div,
	> a {
		@include respond-to($vpC) {
			position: relative;
			&:before {
				content:"";
				width: 1px;
				background-color: $navy60;
				height: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				left: -$space;
			}
			&:first-child {
				&:before {
					display: none;
				}
			}
		}
	}
}

.grid__col {
	position: relative;
	@include pie-clearfix;
	img,
	iframe {
		max-width: 100%;
	}
}