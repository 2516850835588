@use 'sass:math';

.block {
	margin-top: $space*2;
	&:first-of-type {
		margin-top: 0;
	}
}

.block__heading {
	@extend .heading;
    line-height: 1.3;
    font-size: rem($typeL);
    font-weight: $fontBold;
    margin-bottom: $space;
}