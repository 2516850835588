@use 'sass:math';

.bubble,
a.bubble {
	color: $navy;
	border: 3px solid $navy80;
	background-color: $navy20;
	padding: $space*.15 $space;
	border-radius: $space;
	font-size: rem($typeL);
	display: inline-block;
	vertical-align: top;
	line-height: 30px;
	&--small {
		font-size: rem($typeMe);
		border-width: 2px;
		padding: 0 $space*.5;
		line-height: 22px;
		.hero & {
			font-size: rem($typeM);
		}
	}
	&--theme {
		border-color: $orange80;
		background-color: $orange20;
	}
	&--workstream {
		border-color: $green80;
		background-color: $green20;
	}
	.card & {
		margin-top: $space*.5;
	}
	& + .bubble {
		// margin-left: $space;
	}
}

a.bubble {
	@include link-active-styles {
		border-color: $navy;
		background-color: $navy40;
	}
	&--theme {
		@include link-active-styles {
			border-color: $orange;
			background-color: $orange40;
		}
	}
	&--workstream {
		@include link-active-styles {
			border-color: $green;
			background-color: $green40;
		}
	}
}

.bubble-wrap {
	line-height: 54px;
	&--small {
		line-height: 46px;
	}
	.bubble {
		margin-right: $space*.5;
	}
	.hero &{
		line-height: 50px;
		.bubble {
			position: relative;
			top: 4px;
			margin-left: 0;
		}
	}
}

