// Maps
// ------------------------------------------------------------

.map {
	@include ratio(4 3);
	position: relative;
	overflow: hidden;
	margin-bottom: $space;
	@include respond-to-max($vpC) {
		margin-top: $space*2;
	}
}

.map__location {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}