.pagination {
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: $space*3;
    .pagination__item-link {
        font-size: rem($typeMe);
    }
}

.pagination__item {
    font-size: rem($typeMe);
    display: inline-block;
    display: none;
    @include respond-to($vpC) {
        display: block;
    }
}

.pagination__item-ellipsis {
	color: $green;
	font-weight: $fontSemiBold;
	margin: 0 5px;
}

.pagination__item-link {
    display: block;
    line-height: $space*2;
    width: $space*2;
    text-align: center;
    margin: 0 5px;
}

a.pagination__item-link {
    transition: all .3s ease-in-out;
    border-bottom: 0;
    border-radius: $space;
    @include link-active-styles {
    	border-bottom: 0;
        background-color: $yellow40;
    }
}

.pagination__item-link--active {
    font-weight: $fontSemiBold;
    background-color: $yellow40;
}

.pagination__item--prev,
.pagination__item--next {
	display: block;
	position: relative;
   	span {
   		position: absolute;
   		left: -9999px;
   	}
   	svg {
   		width: 30px;
   		height: 30px;
   		margin-top: 4px;
   		stroke: $green;
   		stroke-width: 1.5px;
   	}
}

.pagination__item--prev {
    margin-right: $space*.5;
    svg {
    	margin-right: 3px;
    }
}
.pagination__item--next {
    margin-left: $space*.5;
    svg {
    	margin-left: 3px;
    }
}

.pagination__item-prev-link,
.pagination__item-next-link {
    height: $space*2;
    width: $space*2;
    text-align: center;
    transition: all 0.3s ease-in-out;
    border-radius: $space;
    border-bottom: 0;
    display: block;
    border: 1px solid $green20;
    @include link-active-styles {
        background: $cButtonPrimary;
        border-bottom: 0;
        border-color: $cButtonPrimary;
        svg {
        	stroke: $white;
        }
    }
}
