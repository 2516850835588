@use 'sass:math';

.drawer {
    +.drawer {
        margin-top: $space;
    }
}

.drawer[open] {
    .drawer__title {
        background-color: darken($grey60,10%);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .drawer__title::before {
        transform: rotate(0deg);
        left: 17px;
        top: 19px;
        background-image: url(/assets/icons/source/drop.svg);
    }
}

.drawer__title {
    position: relative;
    font-size: rem($typeM);
    font-weight: $fontBold;
    padding: math.div($space,2) $space;
    padding-left: $space*2.5;
    background-color: $grey60;
    transition: all, ease-in-out, 0.1s;
    cursor: pointer;
    border-radius: 5px;
    list-style: none;
    &::-webkit-details-marker {
        display: none;
    }
    &:before {
        content: "";
        background-image: url(/assets/icons/source/drop-blue.svg);
        transform: rotate(-90deg);
        width: 15px;
        height: 15px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: $space;
        display: block;
        position: absolute;
        left: 15px;
        top: 21px;
        transition: all, ease-in-out, 0.1s;
    }
}

.drawer__content {
    padding: $space;
    background-color: $grey60;
    @include respond-to($vpC) {
        padding: $space math.div($space,2) $space $space*2.5;
    }
    &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .typebase {
        h1,h2,h3,h4,h5,h6,p,ol,ul,li {
            color: $cHeading;
        }
    }
}

.drawer__content__heading {
    @extend .heading;
    font-size: rem($typeL);
    margin-bottom: $space;
}