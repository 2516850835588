@use 'sass:math';

.card {
	position: relative;
	background-color: $green20;
	display: block;
	overflow: hidden;
	.grid__col & {
		margin-top: $space;
	}
	&--horizontal,
	&--team {
		display: flex;
		overflow: visible;
	}
	&--team {
		background-color: $green40;
	}
	&--person {
		background-color: $yellow20;
	}
	&--highlight {
		background-color: $yellow40;
	}
}

a.card {
	border-bottom: none;
	transition: all, ease-in-out, 0.3s;
	@include link-active-styles {
		transform: scale(1.05);
		background-color: $green40;
	}
	&.card--highlight {
		@include link-active-styles {
			background-color: $yellow60;
		}
	}
}

.section--highlight-2 a.card--news {
	background-color: $white;
	@include link-active-styles {
		background-color: $white;
	}
}

.card__media {
	overflow: hidden;
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	display: block;
	background-color: $grey20;
	min-height: 240px;
	.card--function & {
		min-height: 200px;
	}
	.card--horizontal & {
		min-height: 120px;
		display: flex;
		width: 30%;
	}
	.card--team & {
		background-color: $green80;
		background-position: center;
		display: block;
		@include ratio(4 3);
	}
}

.card__motif {
	position: absolute;
	left: 0;
	top: 0;
	background-color: $green80;
	background-size: 60%;
	background-position: center;
	width: 120px;
	height: 80px;
	z-index: 999;
}

a.card__media {
	border-bottom: none;
}

.card__content {
	z-index: 5;
	position: relative;
	padding-bottom: $space;
	.card--horizontal &,
	.card--team & {
		width: 100%;
		flex: 1;
		@include respond-to($vpC) {
			width: 70%;
		}
	}
	p:last-child{
		margin-bottom: 0;
	}
	.card--centred &{
		text-align: center;
	}
}

.card__heading {
	@extend .heading;
	font-size: rem($typeL);
	font-weight: $fontBold;
	line-height: 1.3;
	transition: all, ease-in-out, 0.3s;
	padding: $space*.5 $space;
	.card--person & {
		background-color: $yellow40;
		margin-bottom: $space*.5;
	}
	.card--team & {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		font-size: rem($typeXL);
	}
	svg {
		width: 20px;
		height: 20px;
		vertical-align: text-top;
		margin: 0 10px 0 0;
		display: inline-block;
		filter: brightness(0) saturate(100%) invert(47%) sepia(82%) saturate(1971%) hue-rotate(336deg) brightness(111%) contrast(84%);
	}
	a {
		border-bottom-color: transparent;
		@include link-active-styles() {
			border-bottom-color: $navy;
		}
	}
}

.card__excerpt {
	padding: $space*.25 $space;
	font-size: rem($typeMe);
	margin-bottom: 0;
	@extend .break-words;
	.card--person.card--horizontal & {
		background-color: $yellow20;
		font-size: rem($typeXS);
	}
	p {
		margin-top: rem($typeDefault);
		display: block;
		&:first-child{
			margin-top: 0;
		}
	}
}

.card__meta {
	padding: $space*.25 $space;
	font-size: rem($typeXS);
	position: relative;
	list-style: none;
	.card--person & {
		background-color: $yellow20;
	}
	.card__meta__heading {
		font-weight: $fontSemiBold;
	}
	li {
		margin-bottom: 5px;
		svg {
			width: 20px;
			height: 20px;
			vertical-align: text-top;
			margin: 0 10px 0 0;
			display: inline-block;
			stroke: $purple;
			stroke-width: 1.5px;
			&:nth-child(2n){
				margin-left: $space;
			}
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.card__list {
	@extend .unordered_list;
	font-size: rem($typeS);
	position: relative;
	margin-bottom: $space*.5;
}