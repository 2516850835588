@use 'sass:math';

.m-listing {
	@include pie-clearfix;
    position: relative;
    display: block;
    clear: both;
    .card {
    	margin-bottom: $space*2;
    	&:last-child {
    		margin-bottom: 0;
    	}
    }
}

.listing-item {
    @include pie-clearfix;
    position: relative;
    margin-bottom: $space*2;
    padding-bottom: $space*2;
    border-bottom: 1px solid $grey20;
    &:last-of-type {
    	border-bottom: 0;
    }
}

.listing-item__heading {
    @extend .heading;
    font-size: rem($typeL);
    line-height: 1.4;
    margin-bottom: math.div($space,2);
}

.listing-item__subheading {
    font-size: rem($typeDefault);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: math.div($space,2);
}

.listing-item__meta {
    list-style: none;
    font-size: rem($typeDefault);
    margin: math.div($space,1.5) 0 math.div($space,0);
    li {
        display: inline-block;
        margin-right: math.div($space,2);
        // svg {
        //     width: 17px;
        //     height: 17px;
        //     vertical-align: text-top;
        //     margin-right: 10px;
        //     display: inline-block;
        //     fill: $grey20;
        // }
    }
}

.listing-item__content {
	font-size: rem($typeDefault);
}