@use 'sass:math';

.form {
	position: relative;
	// background-color: $grey20;
	// padding: $space;
	// border-radius: $border-radius;
	&--spaced {
		margin: $space 0;
	}
}

.fieldset {
	border: none;
	padding: 0;
	margin-bottom: $space;
	&:last-child {
		margin-bottom: 0;
	}
	legend {
		@extend .heading;
		font-size: rem($typeL);
		margin-bottom: 12px;
		position: relative;
	}
}

fieldset.form-field legend {
	margin-bottom: 0;
}

// Labels
.form-label {
	font-size: rem($typeS);
	font-weight: $fontBold;
	color: $cLabel;
	margin-bottom: 12px;
	position: relative;
	&--hidden {
		@include a11yhide;
	}
	svg {
		width: 15px;
		height: 15px;
		// display: inline-block;
		// margin-left: 0px;
		position: absolute;
		right: -20px;
		top: 0;
	}
}

// Form field wrapper
.form-field {
	position: relative;
	@include pie-clearfix;
	&--spaced {
		padding-top: math.div($space,1.5);
	}
	&--error {
		.form-item {
			border-color: $cError;
			&:focus {
				border-color: $cError;
			}
		}
		.form-label {
			color: $cError;
		}
	}
	&--select-button {
		@include pie-clearfix;
		.form-field__select {
			float: left;
			position: relative;
			select {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		.button {
			float: left;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.hint {
		font-size: rem($typeS);
		padding-top: 10px;
		clear: left;
		display: block;
	}
	&__error__message {
		color: $cError;
		display: none;
		font-size: rem($typeXS);
		padding-top: 6px;
		clear: left;
		&--visible {
			display: block;
		}
	}
	&__pair {
		@include respond-to($vpC) {
			width: 49.5%;
			margin-left: 1%;
			display: inline-block;
			margin-bottom: 0;
			float: left;
			&:first-child {
				margin-left: 0;
			}
		}
	}
	&__triplet {
		@include respond-to($vpC) {
			width: 32.5%;
			margin-bottom: 0;
			margin-left: 1.25%;
			display: inline-block;
			margin-bottom: 0;
			float: left;
			&:first-child {
				margin-left: 0;
			}
		}
	}
	.hint {
		font-size: rem($typeXS);
		padding-top: 6px;
		clear: left;
		display: block;
	}
}

// Standard form elements
.form-item {
	padding: 10px;
	display: block;
	border: $strokeWidth solid $grey60;
	appearance: none;
	background-color: #fff;
	height: $formItem;
	width: 100%;
	font-size: 15px; // Stop IOS doing the zoom thing, the Git!
	border-radius: $btnRadius;
	margin-top: 6px;
	&::focus-inner {
		border: 0;
		padding: 0;
	}
	&:focus {
		outline: none;
		border-color: $grey;
	}
	&--small {
		height: math.div($formItem,1.25);
	}
}

// Turn off spinners for number input field, as flipping ugly
.form-item[type=number]::-webkit-inner-spin-button,
.form-item[type=number]::-webkit-outer-spin-button {
	appearance: none;
}

// Min height to text area
textarea.form-item {
	min-height: 200px;
}

.form-meta {
	font-size: rem($typeS);
	margin-top: $space;
}

// Radio and Checkboxes baseline
// -----------------------

.form-field__radio-buttons,
.form-field__checkboxes {
	margin-top: 6px;
	label {
		position: relative;
		top: 0;
		vertical-align: middle;
		&:after {
			clear: left;
			content: "";
			display: block;
			height: 0;
		}
	}
	input {
		float: left;
		margin-right: 12px;
		vertical-align: middle;
		&:after {
			clear: left;
			content: "";
			display: block;
			height: 0;
		}
	}
	input[type="radio"],
	input[type="checkbox"] {
		width: 16px;
		height: 16px;
		position: relative;
		top: 4px;
	}
	input[type="radio"] {
		width: 16px;
		height: 16px;
		position: relative;
		top: 3px;
	}
}

// Select baseline
// -----------------------

.form-field__select {
	background-color: #fff;
	margin-top: 6px;
	select, .select2-container {
		width: 100%;
		display: block;
		height: $formItem;
		padding: 0px 5px 0 5px !important;
		vertical-align: middle;
		border: $strokeWidth solid $grey60;
		appearance: none;
		-moz-appearance: window; // Hides firefox native select arrow
		background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") #fff no-repeat right 47%;
		background-origin: content-box;
		background-size: 22px 19px;
		background-position: 98% 50%;
		border-radius: 0;
		font-size: 15px;
		line-height: 15px;
		border-radius: $btnRadius;
		&:hover, &:focus {
			border-color: $grey;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
		&::-ms-expand {
			display: none;
		}
		.formfieldError & {
			border-color: $cError;
		}
		option {
			max-width: 90%;
		}
	}
	// &--small {
	// 	height: math.div($formItem,1.25);
	// 	select {
	// 		height: math.div($formItem,1.25);
	// 	}
	// }
	.select2-container {
		width: 100% !important;
		font-size: rem($typeS);
		padding: 0;
		height: auto !important;
		min-height: $formItem;
	}
	.select2-selection--multiple {
		min-height: $formItem !important;
		background-color: transparent !important;
		border: none !important;
		padding-top: 5px !important;
	}
	.select2-selection__choice {
		display: inline-flex !important;
		font-size: rem($typeS);
		margin-top: 2px !important;
		margin-left: 2px !important;
		background-color: $grey40 !important;
		border: 1px solid $grey60 !important;
	}
	.select2-selection__choice__remove {
		font-size: 20px !important;
		color: $grey !important;
		border-color: $grey60 !important;
		padding: 4px 4px !important;
		&:hover {
			background-color: $grey !important;
		}
	}
	.select2-selection__choice__display {
		transform: translateY(-2px);
		display: inline-block;
		padding: 5px !important;
		padding-bottom: 0 !important;
	}
}

.select2-container .select2-search--inline .select2-search__field {
	margin-top: 8px !important;
}

.select2-results__option {
	font-size: rem($typeS) !important;
}
.select2-results__option--highlighted.select2-results__option--selectable {
	background-color: $yellow !important;
	color: $cHeading !important;
}
.select2-container--default .select2-results__option--selected {
	background-color: $grey60 !important;
}

.grecaptcha-badge { visibility: hidden; }

// Date input
.form-field__date {
	font-size: rem($typeMe);
	input {
		width: auto;
	}
	.form-field__triplet & {
		input {
			width: 100%;
		}
	}
}

// @use 'sass:math';

// .form {
// 	position: relative;
// 	// margin-top: $space;
// }

// .fieldset {
// 	border: none;
// 	padding: 0;
// }

// .form-legend {
// 	font-size: rem($typeM);
// 	color: $cLabel;
// 	margin-top: $space;
// }


// // Labels
// .form-label {
// 	font-size: rem($typeS);
// 	color: $cLabel;
// 	@extend .heading;
// 	&--hidden {
// 		@include a11yhide;
// 	}
// }

// // Legend
// .form-legend {
// 	font-size: rem($typeM);
// 	color: $cLabel;
// }

// // Form field wrapper
// .form-field {
// 	position: relative;
// 	@include pie-clearfix;
// 	&--spaced {
// 		padding-top: math.div($space,1.5);
// 	}
// 	&--error {
// 		.form-item {
// 			border-color: $cError;
// 			&:focus {
// 				border-color: $cError;
// 			}
// 		}
// 		.form-label {
// 			color: $cError;
// 		}
// 	}
// 	&--check-radio {
// 		position: relative;
// 		display: block;
// 		@include respond-to($vpB) {
// 			// display: inline-block;
// 		}
// 		> .form-label {
// 			margin-bottom: 12px;
// 			display: block;
// 		}
// 	}
// 	&--select-button {
// 		@include pie-clearfix;
// 		.form-field__select {
// 			float: left;
// 			position: relative;
// 			select {
// 				border-top-right-radius: 0;
// 				border-bottom-right-radius: 0;
// 			}
// 		}
// 		.button {
// 			float: left;
// 			border-top-left-radius: 0;
// 			border-bottom-left-radius: 0;
// 		}
// 	}
// 	&__pair {
// 		@include respond-to($vpC) {
// 			width: 49.5%;
// 			margin-left: 1%;
// 			display: inline-block;
// 			margin-bottom: 0;
// 			float: left;
// 			&:first-child {
// 				margin-left: 0;
// 			}
// 		}
// 	}
// 	&__triplet {
// 		@include respond-to($vpC) {
// 			width: 32.5%;
// 			margin-bottom: 0;
// 			margin-left: 1.25%;
// 			display: inline-block;
// 			margin-bottom: 0;
// 			float: left;
// 			&:first-child {
// 				margin-left: 0;
// 			}
// 		}
// 	}
// 	.hint {
// 		font-size: rem($typeXS);
// 		padding-top: 6px;
// 		clear: left;
// 		display: block;
// 	}
// 	&__error__message {
// 		color: $cError;
// 		display: none;
// 		font-size: rem($typeXS);
// 		padding-top: 6px;
// 		clear: left;
// 		&--visible {
// 			display: block;
// 		}
// 	}
// }

// // Standard form elements
// .form-item,
// .comment-form-comment textarea,
// .comment-form-author input,
// .comment-form-email input,
// .comment-form-url input {
// 	padding: 10px;
// 	display: block;
// 	border: $strokeWidth solid $cBorder;
// 	appearance: none;
// 	background-color: #fff;
// 	height: $formItem;
// 	width: 100%;
// 	font-size: 16px; // Stop IOS doing the zoom thing
// 	border-radius: $btnRadius;
// 	color: $grey;
// 	&::focus-inner {
// 		border: 0;
// 		padding: 0;
// 	}
// 	&:focus {
// 		outline: none;
// 		border-color: $cLabel;
// 	}
// 	&--small {
// 		height: math.div($formItem,1.25);
// 	}
// }

// // Turn off spinners for number input field, as flipping ugly
// .form-item[type=number]::-webkit-inner-spin-button,
// .form-item[type=number]::-webkit-outer-spin-button {
// 	appearance: none;
// }

// // Min height to text area
// textarea.form-item {
// 	min-height: 200px;
// }

// // File form item
// .form-item-file {
// 	display: block;
// 	font-size: rem($typeMe);
// 	color: $cLabel;
// }

// // Radio and Checkboxes baseline
// // -----------------------

// .form-field__radiobuttons,
// .form-field__checkboxes,
// .form-field__radio-buttons {
// 	label {
// 		position: relative;
// 		top: 0;
// 		vertical-align: middle;
// 		&:after {
// 			clear: left;
// 			content: "";
// 			display: block;
// 			height: 0;
// 		}
// 		&.form-label {
// 			font-weight: normal;
// 		}
// 	}
// 	input {
// 		float: left;
// 		margin-right: 8px;
// 		vertical-align: middle;
// 		&:after {
// 			clear: left;
// 			content: "";
// 			display: block;
// 			height: 0;
// 		}
// 	}
// 	input[type="radio"],
// 	input[type="checkbox"] {
// 		width: 16px;
// 		height: 16px;
// 		position: relative;
// 		top: 4px;
// 	}
// 	input[type="radio"] {
// 		width: 16px;
// 		height: 16px;
// 		position: relative;
// 		top: 5px;
// 	}
// }

// // Select baseline
// // -----------------------

// .form-field__select {
// 	background-color: #fff;
// 	height: $formItem;
// 	select {
// 		width: 100%;
// 		display: block;
// 		height: $formItem;
// 		padding: 2px 15px 0 15px;
// 		vertical-align: middle;
// 		border: $strokeWidth solid $cBorder;
// 		appearance: none;
// 		-moz-appearance: window; // Hides firefox native select arrow
// 		background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") #fff no-repeat right 47%;
// 		background-origin: content-box;
// 		background-size: 22px 19px;
// 		border-radius: 0;
// 		font-size: 16px;
// 		border-radius: $btnRadius;
// 		&:hover, &:focus {
// 			border-color: $cLabel;
// 			cursor: pointer;
// 		}
// 		&:focus {
// 			outline: none;
// 		}
// 		&::-ms-expand {
// 			display: none;
// 		}
// 		.formfieldError & {
// 			border-color: $cError;
// 		}
// 		option {
// 			max-width: 90%;
// 		}
// 	}
// 	&--small {
// 		height: math.div($formItem,1.25);
// 		select {
// 			height: math.div($formItem,1.25);
// 		}
// 	}
// }