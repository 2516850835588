@use 'sass:math';

.header {
	display: block;
	background-color: $navy;
	z-index: 5;
	// @include respond-to($vpMenu) {
		// position: absolute;
		// top:0;
		// width: 100%;
		// overflow: visible!important;
	// }
}

.header__logo {
	margin: $space*.75 0;
	width: 245px;
	height: 33px;
	background-image: url('../img/nihr-bristol-crf-white.svg');
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	text-indent: -9999em;
	border-bottom: none;
	color: #fff;
	@include link-active-styles {
		border-bottom: none;
	}
	@include respond-to-max($vpD) {
		margin-left: $space;
	}
	@include respond-to-min-max($gridMax,$gridMax+40) {
		margin-left: $space;
	}
}