@use 'sass:math';

.with-leader {
	margin-top: $space;
}

.with-leader--long {
	margin-top: $space*2.65;
}

.with-trailer {
	margin-bottom: $space;
}