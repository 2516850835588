@use 'sass:math';

.footer {
    background-color: $navy;
    color: $white;
    padding: $space*3 $space $space*8;
    @include respond-to($vpC) {
    	padding: $space*3 0 $space*6;
    }
}

.footer__icon {
	position: absolute;
	bottom: -60px;
	@include respond-to($vpC) {
		bottom: 0;
	}
	@include respond-to($vpMax) {
		left: 0;
	}
}

.footer__heading {
    font-size: rem($typeL);
    margin-bottom: $space;
    display: block;
}

.footer__copy {
    font-size: rem($typeS);
    margin-bottom: $space;
    a {
        color: #fff;
        text-decoration: underline;
        border-bottom: none;
    }
}

.footer__list,
.footer__nav {
    list-style: none;
    font-size: rem($typeMe);
    margin-bottom: $space*2;
}

.footer__list__item {
    margin-bottom: $space;
    // height: 40px;
   	padding-left: 30px;
   	position: relative;
    a {
        color: inherit;
        border-bottom: 0;
        font-weight: $fontBold;
        border-bottom: 2px solid transparent;
        &:hover, &:focus {
        	border-color: $navy40;
        }
    }
    &--active a {
    	text-decoration: none;
    }
}

.footer__list__item__icon {
	position: absolute;
	left: 0;
	top: 0;
	margin-right: 5px;
	display: inline-block;
	svg {
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }
}

.footer__nav__item {
    position: relative;
    margin-bottom: $space;
    a {
        color: inherit;
        border-bottom: 0;
        display: inline-block;
        position: relative;
        font-weight: $fontBold;
        border-bottom: 2px solid transparent;
        &:hover, &:focus {
            border-color: $navy40;
        }
    }
    &--active a {
        font-weight: $fontSemiBold;
        text-decoration: none;
    }
}

.footer__meta {
    font-size: rem($typeXS);
    color: #fff;
    padding-top: $space*2;
    // @include respond-to($vpC) {
    // 	position: absolute;
	// 	bottom: 0;
	// 	right: 0;
    // }
    p {
    	margin-bottom: $space;
    }
    a {
        color: #fff;
        text-decoration: underline;
        border-bottom: none;
    }
}