@use 'sass:math';

// ------------------------------------------------------------
// Navigation
// ------------------------------------------------------------

.navigation-primary {
	display: block;
	border-top: 1px solid $navy80;
	overflow: visible!important;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		height: 1px;
		bottom: 0;
		width: 100%;
		display: block;
		background-color: $navy80;
		z-index: 10;
	}
	@include respond-to-max($vpMenu) {
		overflow: hidden;
	}
}

.navigation-primary-toggle {
	position: absolute;
	top: 8px;
	right: $space;
	width: 65px;
	height: 44px;
	display: inline-block;
	text-align: left;
	cursor: pointer;
	padding: 0 0 0 0;
	font-size: rem($typeS);
	font-weight: $fontSemiBold;
	line-height: 44px;
	&:before, &:after {
		content: "";
		position: absolute;
		height: 2px;
		width: 16px;
		background-color: $white;
		transition: all, ease-in-out, 0.3s;
	}
	&:before {
		top: 15px;
		right: 0;
	}
	&:after {
		bottom: 15px;
		right: 8px;
		width: 8px;
	}
	span:after {
		content: "";
		position: absolute;
		height: 2px;
		width: 16px;
		background-color: $white;
		transition: all, ease-in-out, 0.3s;
		right: 0;
		top: 21px;
	}
	@include respond-to($vpMenu) {
		display: none;
	}
	&--open {
		span:after {
			background-color: transparent;
		}
		&:before {
			transform: rotate(45deg);
			width: 20px;
			top: 21px;
			right: 0;
		}
		&:after {
			transform: rotate(-45deg);
			width: 20px;
			bottom: 21px;
			right: 0;
		}
	}
}

.navigation-primary__items {
	background-color: $navy;
	overflow: hidden;
	position: relative;
	transition: max-height ease-in-out 0.25s;
	width: 100%;
	@include respond-to-max($vpMenu) {
		html.js & {
			max-height: 0;
			overflow: hidden;
		}
	}
	@include respond-to($vpMenu) {
	display: flex;
	align-items: flex-start;
	align-items: stretch;
	flex-wrap: nowrap;
	overflow: visible;
	}
	&--open {
		html.js & {
			max-height: 100%;
			width: 100%;
			left: 0;
		}
	}
}

.navigation-primary__item {
	@include pie-clearfix;
	font-size: rem($typeMe);
	line-height: 1.5;
	svg {
		height: 20px;
		width: 20px;
		top: -2px;
		position: relative;
		margin-right: $space*.5;
	}
	a {
		color: $white;
		padding: $space*.65 $space;
		transition: all ease-in-out, 0.2s;
		border-bottom: 0;
		background-color: transparent;
		display: inline-block;
		height: 100%;
		width: 100%;
		position: relative;
		@include link-active-styles {
			@include respond-to-max($vpMenu) {
				padding-left: 25px;
				svg {
					left: 0;
					opacity: 1;
				}
			}
			@include respond-to($vpMenu) {
				background-color: $green40;
				color: $navy;
				&:before {
					background-color: transparent;
				}
			}
		}
		@include respond-to($vpMenu) {
			text-align: center;
			padding: $space*.5 $space;
			&:before {
				content:"";
				position: absolute;
				top: 10px;
				bottom: 10px;
				left: 0;
				width: 1px;
				background-color: $navy80;
			}
			@include link-active-styles {
				background-color: $green40;
				z-index: 1000;
			}
		}
		@include respond-to-max($vpMenu) {
			display: block;
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: $navy60;
				height: 1px;
			}
		}
		svg {
			position: absolute;
			left: -20px;
			top: 15px;
			opacity: 0;
			transition: all, ease-in-out, 0.2s;
			@include respond-to($vpMenu) {
				top: -9999px;
			}
		}
	}
	@include respond-to-max($vpMenu) {
		position: relative;
		ul li {
			a {
				padding-left: $space*2;
			}
			ul li {
				a {
					padding-left: $space*3;
				}
			}
			ul li {
				a {
					padding-left: $space*4;
				}
			}
		}
	}
	@include respond-to($vpMenu) {
		float: left;
		flex: 1 1 auto;
		font-size: 1.6rem;
		&:first-child a {
			&:before {
				background-color: transparent;
			}
		}
	}
	&--active,
	&.current-menu-item,
	&.current-page-ancestor,
	&.current_page_parent {
		z-index: 1000;
		> a {
			@include respond-to($vpMenu) {
				background-color: $green40;
				color: $navy;
			}
			@include link-active-styles {
				@include respond-to($vpMenu) {
					background-color: $green60;
				}
			}
		}
		@include respond-to-max($vpMenu) {
			background-color: $navy80;
		}
		@include respond-to($vpMenu) {
			text-indent: 0;
			> a {
				background-color: $green40;
				&:before {
					background-color: transparent;
				}
			}
		}
		svg {
			left: 0;
		}
	}
	&--ss-only {
		@include respond-to($vpMenu) {
			display: none;
		}
		svg {
			stroke: $navy;
    		stroke-width: 1.5px
		}
	}

}

// ------------------------------------------------------------
// Search
// ------------------------------------------------------------

.navigation-primary__item--search {
	padding: $space;
	color: $navy;
	border-top: 1px solid $navy80;
	form {
		position: relative;
	}
	label {
		@include a11yhide;
	}
	input {
		padding: 10px 15px;
		width: 100%;
		border: 0;
		line-height: 1;
		background-color: $white;
		border-radius: 3px;
		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		  color: $navy40;
		  opacity: 1; /* Firefox */
		}
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		  color: $navy40;
		}
		&::-ms-input-placeholder { /* Microsoft Edge */
		  color: $navy40;
		}
	}
	button {
		height: 40px;
		position: absolute;
		top: 0;
		right: 0;
		text-indent: -9999px;
		svg {
			margin: 0 0 0 6px;
			transition-duration: 0.3s;
			transition-property: transform;
			transition-timing-function: ease-out;
			transform-origin: top;
			stroke: $green;
			stroke-width: 1.5px;
		}
		@include link-active-styles {
			svg {
				animation-name: hvr-icon-pop;
				animation-duration: 0.3s;
				animation-timing-function: linear;
				animation-iteration-count: 1;
			}
		}
	}
}

// ------------------------------------------------------------
// Secondary Navigation
// ------------------------------------------------------------

.navigation-secondary {
	display: none;
	@include respond-to($vpMenu) {
		display: block;
		position: absolute;
		top: $space*.75;
		right: 0;
		@include respond-to-max($gridMax+40) {
			right: $space;
		}
	}
}

// ------------------------------------------------------------
// Multi navigation
// ------------------------------------------------------------

.navigation-primary__multinav {
	display: none;
	@include respond-to-max($vpMenu) {
		position: relative;
		z-index: 999;
		// &:before {
		// 	content: "";
		// 	position: absolute;
		// 	top: 0;
		// 	left: $space;
		// 	right: $space;
		// 	background-color: $navy60;
		// 	height: 1px;
		// }
	}
	.navigation-primary__item {
		// background-color: $navy80;
		&:last-child {
			// border-bottom: 1px solid $navy60;
		}
		a {
			@include respond-to($vpMenu) {
				position: relative;
				&:before {
					content: "";
					position: absolute;
					top: 5px;
					left: 0;
					right: 5px;
					background-color: $navy60;
					height: 1px;
					z-index: 999;
				}
			}
		}
		ul {
			// display: none;
			// padding-left: $space;
			li {
				&:last-child {
					// border-bottom: none;
				}
				a {

				}
			}
		}
	}
	.navigation-primary__item--active &,
	.current-page-ancestor &,
	.current_page_parent &,
	.navigation-primary__item:hover &,
	.navigation-primary__item:focus &,
	.navigation-primary__item:focus-within &
	{
		a {
			&:before {
				background-color: $navy60;
			}
		}
		@include respond-to($vpMenu) {
			display: block;
			position: absolute;
			left: 0;
			// bottom: -44px;
			// top: 68px;
			height: auto;
			width: 100%;
			background-color: red;
			z-index: 100;
			background-color: $green40;
			li {
				display: inline-block;
				float: left;
				position: relative;
				a {
					background-color: $green40;
					color: $navy;
					position: relative;
					display: inline-block;
					z-index: 100;
					&:before {
						content: "";
						position: absolute;
						top: 10px;
						left: 0;
						bottom: 10px;
						background-color: $green20;
						right: auto;
						height: auto;
						width: 1px;
						z-index: 99;
					}
					@include link-active-styles {
						background-color: $green60;
						border-bottom-color: $green60;
					}
				}
				&:first-child a:before {
					background-color: transparent;
				}
				&.navigation-primary__item--active,
				&.current-page-ancestor
				&.current_page_parent {
					a {
						background-color: $green60;
					}
				}
			}
		}
	}
	.navigation-primary__item:hover &,
	.navigation-primary__item:focus &,
    .navigation-primary__item:focus-within & {
		@include respond-to($vpMenu) {
			z-index: 9999;
		}
	}
}

.navigation-primary__multinav-trigger {
	position: absolute;
	z-index: 99;
	right: 0px;
	top: 0;
	display: block;
	height: 50px;
	width: 50px;
	cursor: pointer;
	&:after {
		position: absolute;
		top: 9px;
		left: 9px;
		width: 32px;
		height: 32px;
		display: block;
		background-image: url('../icons/source/chevron-down.svg');
		background-repeat: no-repeat;
		background-size: cover;
		content: '';
	}
	&--active {
		&:after {
			background-image: url('../icons/source/chevron-up.svg');
		}
	}
	@include respond-to($vpMenu) {
		display: none;
	}
	@include link-active-styles {
		background: $navy60;
		&:after {
			filter: brightness(0) saturate(100%) invert(16%) sepia(74%) saturate(1553%) hue-rotate(197deg) brightness(93%) contrast(89%);
		}
	}
	span {
		@include a11yhide;
	}
}