@use 'sass:math';

.media-video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	clear: both;
	overflow: hidden;
	.typebase & {
		margin: $space 0 0 0;
	}
	object,embed,iframe  {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.wp-caption, .media-figure {
    margin: math.div($space*2,1.5) 0 0 0;
    img {
        @extend .max-width;
    }
}

.wp-caption-text, .media-figure__caption {
    background-color: $green20;
    padding: 2px 10px;
    font-size: rem($typeXS);
    display: inline-block;
}

.alignleft, .left {
    float: left;
    margin: math.div($space*2,1.5) math.div($space*2,1.5) 0 0;
    img {
        @extend .max-width;
    }
}

.alignright, .right {
    float: right;
    margin: math.div($space*2,1.5) 0 0 math.div($space*2,1.5);
    img {
        @extend .max-width;
    }
}